<template>
  <b-card>
    <form-component @onSubmit="onSubmit" :inspiration="inspiration" :posts="posts" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/inspirations/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/inspirations';
import { all } from '@/http/blog/posts';
import { createInspirationForm } from '@/constants/utils/form';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      lang: this.$route.params.lang,
      inspiration: null,
      posts: [],
    };
  },

  async created() {
    if (!this.$route.params?.slug || !this.$route.params?.lang) {
      this.$router.push({ name: 'inspirations-index' });
      this.showErrorNotification('Wybierz inspirację z listy');

      return;
    }

    await Promise.all([this.fetchIsnpiration(), this.fetchPosts()]);
  },

  methods: {
    async fetchIsnpiration() {
      try {
        const { data } = await show(this.slug, this.lang);

        this.inspiration = data.data;
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił problem z pobraniem inspiracji. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z pobraniem danych', message);
        this.$router.push({ name: 'inspirations-index' });
      }
    },

    async fetchPosts() {
      try {
        const { data } = await all();

        this.posts = data.data;
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z pobraniem postów. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z pobraniem danych', message);
      }
    },

    async onSubmit(form) {
      const formData = createInspirationForm(form);

      try {
        await update(this.inspiration.slug, formData);

        this.showSuccessNotification('Dane zostały zapisane', 'Inspiracja została dodany.');
        this.$router.push({ name: 'inspirations-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił problem z zapisem inspiracji. Skontaktuj się ze swoim developerem.';

        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>

<style lang="scss"></style>
